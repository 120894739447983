<template>
  <common-form
    ref="form"
    url="role"
    title="设备大屏设置"
    :items="items"
    @success="$emit('success')"></common-form>
</template>
<script>

  export default {
    data() {
      return {
        edit: false,
      }
    },
    computed: {
      items() {
        return [
          {
            type: "input",
            name: "大屏标题",
            key: "title",
          },
          {
            type: "textarea",
            name: "公告栏内容",
            key: "content",
          },
          {
            type: "image",
            name: "背景",
            key: "background",
          },
          {
            type: "color",
            name: "颜色",
            key: "color",
          },
        ]
      }
    },
    methods: {
      show(model = {}) {
        this.$refs.form.show(model)
      },

    }

  }
</script>
