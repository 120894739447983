<template>
    <page-content title="设备详情">
        <a-descriptions :column="1" bordered size="small" style="margin-bottom: 20px">
            <a-descriptions-item label="产品">
                <a-avatar shape="square" :size="50" :src="device.devicePicture"
                          style="background: #F8F8F8;margin-right: 10px"/>
                {{device.productName}}
            </a-descriptions-item>
            <a-descriptions-item label="设备ID">{{device.iotId}}</a-descriptions-item>
            <a-descriptions-item label="设备MAC">{{device.mac}}</a-descriptions-item>
            <a-descriptions-item label="设备名称">{{device.nickname}}</a-descriptions-item>
            <a-descriptions-item label="操作">
                <a-button @click="clickParaSetting">设备参数设置</a-button>
                <a-button @click="clickScreenSetting">大屏</a-button>
            </a-descriptions-item>
        </a-descriptions>

        <device-para-setting ref="deviceParaSetting"></device-para-setting>
        <device-screen-setting ref="deviceScreenSetting"></device-screen-setting>

    </page-content>
</template>
<script>
    import DeviceParaSetting from "./setting/DeviceParaSetting";
    import DeviceScreenSetting from "./setting/DeviceScreenSetting";
    export default {
        components: {
            DeviceParaSetting,DeviceScreenSetting
        },
        data() {
            return {
                getDataInterval: null,
                device: {
                    name: "",
                    iotId: "",
                    mac: "",
                    activeTime: "",
                    status: null,
                    firmwareVersion: ""
                },
                valueMap: {},
                userInfo: {
                    loading: false,
                    dataSource: []
                },

                tabList: [
                    {
                        key: "DataMonitor",
                        tab: "数据监控"
                    },
                    {
                        key: "AlarmRecord",
                        tab: "报警记录"
                    },
                    {
                        key: 'UpDownStream',
                        tab: '上下行消息'
                    },

                ],
                tabKey: "DataMonitor",
            };
        },
        computed: {

        },
        created() {
            let iotId = this.$route.params.iotId
            this.device.iotId = iotId
            // 获取设备详细信息
            this.$get(`device/${iotId}`, {}).then(r => {
                this.device = r.data;
            });
            //获取绑定了设备的用户
            this.$get(`device/${iotId}/users`, {}).then(r => {
                this.userInfo.dataSource = r.data;
            });

            //获取设备参数
            this.getDataInterval = setInterval(() => {
                this.$get(`device/data/${iotId}`, {}).then(r => {
                    let map = {}
                    r.data.forEach(item => {
                        map[item.index + ""] = item.value
                    })
                    this.valueMap = {
                        ...this.valueMap,
                        ...map
                    }
                });
            }, 3000)

        },

        destroyed() {
            clearInterval(this.getDataInterval)
        },

        methods: {

            clickParaSetting(){
                this.$refs.deviceParaSetting.show()
            },
            clickScreenSetting(){
                this.$refs.deviceScreenSetting.show()
            },

            controlDevice(items) {

                const hide = this.$message.loading('正在设置...', 0);

                let key = Object.keys(items)[0]
                let value = items[key]
                this.$post("device/control", {
                    iotId: this.device.iotId,
                    dataIndex: key,
                    value: value
                }).then(suc => {
                    this.valueMap = {
                        ...this.valueMap,
                        ...items
                    }

                    hide()
                }).catch(err => {
                    hide()
                })
            },
        }
    };
</script>
<style lang="less" scoped>

</style>
