<template>
  <common-form
    ref="form"
    url="role"
    title="设备参数设置"
    :items="items"
    @success="$emit('success')"></common-form>
</template>
<script>

  export default {
    data() {
      return {
        edit: false,
      }
    },
    computed: {
      items() {
        return [
          {
            type: "input",
            name: "泳池名称",
            key: "name",
          },
          {
            type: "radio-button",
            name: "泳池类型",
            key: "type",
            options:[
              { name:"室内", value:0 },
              { name:"室外", value:1 },
            ]
          },
          {
            type: "input",
            name: "泳池吨数",
            key: "size",
            suffix: "吨"
          },
          {
            type: "radio",
            name: "使用药剂类型",
            key: "mediType",
            options:[
              { name:"复配药剂", value:0 },
              { name:"次氯酸钙颗粒", value:1 },
              { name:"二氯速溶片", value:2 },
              { name:"三氯氰尿酸", value:3 },
              { name:"二水二氯", value:4 },
            ]
          },
          {
            type: "input",
            name: "循环水量",
            key: "cycleWaterYield",
            suffix: "m3/h"
          },
          {
            type: "input",
            name: "PH",
            key: "ph",
          },
          {
            type: "input",
            name: "余氯值/溴浓度",
            key: "cl",
            suffix: "mg/L"
          },
          {
            type: "input",
            name: "硬度",
            key: "hardness",
            suffix: "HB"
          },
          {
            type: "input",
            name: "碱度",
            key: "basicity",
            suffix: "mmol/L"
          },
          {
            type: "input",
            name: "CYA（氰尿酸）",
            key: "cya",
            suffix: "mmHg"
          },
          {
            type: "input",
            name: "温度",
            key: "temp",
            suffix: "℃"
          },
        ]
      }
    },
    methods: {
      show(model = {}) {
        this.$refs.form.show(model)
      },

    }

  }
</script>
